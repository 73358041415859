// vendors
import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Button = ({
  children,
  primary,
  thin,
  type,
  className,
  onClick,
  ...rest
}) => {
  const emitClick = useCallback(() => {
    if (typeof onClick === 'function') onClick();
  }, [onClick]);

  return (
    <button
      type={type}
      className={classNames([
        'flex-shrink-0 w-full bg-yellow-800 font-sans font-normal text-white py-2 px-8 rounded-sm shadow-md hover:bg-yellow-700 focus-within:[var(--color-outline)] border border-transparent flex items-center justify-center',
        className,
        { 'py-2': thin },
      ])}
      onClick={emitClick}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  primary: PropTypes.bool,
  thin: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  className: PropTypes.string,
  onClick: PropTypes.func,
};
Button.defaultProps = {
  primary: false,
  thin: false,
  type: 'button',
  className: null,
  onClick: () => {},
};

export default Button;
