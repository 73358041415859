import React from 'react';

import Layout from '../components/Layout';
import { UnderlinedButton } from '../components/Button';

const translations = [
  {
    locale: 'fr',
    url: '/',
  },
];

export default function NotFoundPage() {
  return (
    <Layout translations={translations} logoOptions={{ inverted: true }}>
      <div className="container pt-72">
        <h1 className="sr-only">404 Page Not found</h1>

        <p className="text-6xl mb-4">
          Oops!
          <br />
          <span className="text-3xl">
            We can't find the page you're looking for.
          </span>
        </p>

        <UnderlinedButton to="/en" className="text-xl">
          Back to home
        </UnderlinedButton>
      </div>
    </Layout>
  );
}
